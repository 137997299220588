<template>
  <b-card
    class="text-center"
    no-body
    ref="kt_sticky_card"
    id="kt_sticky_card"
  >
    <div class="bg-secondary d-flex align-items-center p-2">
      <span class="font-weight-bolder mr-5">
        <span class="count-status badge badge-circle badge-white mr-1">{{ selectedRequiredStocksCount }}</span>
        Đã chọn
      </span>
      <b-button
        v-if="checkPermission(['PORTAL_FAST_REQUIRE_STOCK_VIEW_TOOL_BAR'])"
        :disabled="!validRequiredStocksToIssue || !validRequiredStocksToIssue.length"
        class="mr-5 btn-pill"
        variant="success"
        size="sm"
        @click="onShowDialogSelectedRequiredStocks"
      ><span style="font-size: 14px">
          <span class="count-status badge badge-circle badge-white mr-1">{{ validRequiredStocksToIssue.length }}</span> Phát hành vận chuyển
        </span></b-button>
    </div>
  </b-card>
</template>

<script>
const { checkPermission } = require('@/utils/saveDataToLocal');
export default {
  props: {
    selectedRequiredStocks: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    selectedRequiredStocksCount() {
      return this.selectedRequiredStocks.length;
    },
    validRequiredStocksToIssue() {
      return this.selectedRequiredStocks.filter(
        (stock) => stock.issuePortalFastStatus.isAvaibleForIssue,
      );
    },
  },
  methods: {
    checkPermission,
    onShowDialogSelectedRequiredStocks() {
      this.$emit('showDialogSelectedRequiredStocks', {
        mode: 'MULTIPLE',
        validSelectedRequiredStocksToIssue: this.validRequiredStocksToIssue,
      });
    },
  },
};
</script>
